import React from "react";
import BannerSlider, { BannerSliderFragment } from "./sections/bannerSlider";
import Bio, { BioFragment } from "./sections/bio";
import Editor, { EditorFragment } from "./sections/editor";

export type PageSection = BannerSliderFragment & EditorFragment & BioFragment;
interface SectionsProps {
  sections: [PageSection];
}

const Sections: React.FC<SectionsProps> = ({ sections }) => {
  return (
    <>
      {sections.map((section, index) => {
        switch (section.__typename) {
          case "WordPress_Page_Pagesections_Sections_BannerSlider": {
            return <BannerSlider key={index} {...section} />;
          }
          case "WordPress_Page_Pagesections_Sections_Editor": {
            return <Editor key={index} {...section} />;
          }
          case "WordPress_Page_Pagesections_Sections_Bio": {
            return <Bio key={index} {...section} />;
          }
          default: {
            return null;
          }
        }
      })}
    </>
  );
};

export default Sections;
