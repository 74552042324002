import { graphql, Link } from "gatsby";
import { FluidObject } from "gatsby-image";
import Img from "gatsby-image/withIEPolyfill";
import React from "react";

export const fragment = graphql`
  fragment BannerSliderFragment on WordPress_Page_Pagesections_Sections_BannerSlider {
    __typename
    fieldGroupName
    rotationTime
    banner {
      content
      fieldGroupName
      title
      image {
        altText
        sourceUrl
        imageFile {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      backgroundColor
      link {
        title
        url
      }
    }
  }
`;

export interface Banner {
  content?: string;
  fieldGroupName: string;
  title?: string;
  image?: {
    altText: string;
    sourceUrl: string;
    imageFile: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
  };
  backgroundColor?: string;
  link?: {
    title: string;
    url: string;
  };
}

export interface BannerSliderFragment {
  __typename: string;
  banner: [Banner];
  rotationTime: number;
  fieldGroupName: string;
}

interface BannerSliderProps {
  banner: [Banner];
  rotationTime: number;
}

/**
 *
 * @todo Add a useRef for focus hijacking and make a slide focused on when user clicks on prev/next buttons
 *
 */

const BannerSlider: React.FC<BannerSliderProps> = ({
  banner = [],
  rotationTime = 5000,
}) => {
  const banners = banner;
  const [activeBanner, setActiveBanner] = React.useState<number>(0);

  const changeBanner = React.useCallback(
    (activeBanner: number, position: string) => {
      switch (position) {
        case "next":
          if (activeBanner < banners.length - 1) {
            setActiveBanner(activeBanner + 1);
          } else {
            setActiveBanner(0);
          }
          break;
        case "prev":
          if (activeBanner > 0) {
            setActiveBanner(activeBanner - 1);
          } else {
            setActiveBanner(banners.length - 1);
          }
          break;
        default:
          break;
      }
    },
    []
  );

  React.useEffect(() => {
    const rotationTimeout = setTimeout(() => {
      if (banners.length > 1) {
        changeBanner(activeBanner, "next");
      }
    }, rotationTime);

    return () => {
      clearTimeout(rotationTimeout);
    };
  }, [activeBanner]);

  if (banners.length === 0) {
    return null;
  }

  return (
    <ul className="relative h-450px">
      {banners.length > 1 && (
        <>
          <button
            className="absolute z-30 left-0 inset-y-0 items-center text-3xl text-scarlet"
            onClick={() => changeBanner(activeBanner, "prev")}
          >
            <span className="sr-only">Previous Slide</span>
            <span aria-hidden="true">&#8592;</span>
          </button>
          <button
            className="absolute z-30 right-0 inset-y-0 items-center text-3xl text-scarlet"
            onClick={() => changeBanner(activeBanner, "next")}
          >
            <span className="sr-only">Next Slide</span>
            <span aria-hidden="true">&#8594;</span>
          </button>
        </>
      )}
      {banners.map((banner, index) => {
        const bannerTitle = banner.title;
        const image = banner.image;
        const backgroundColor = banner.backgroundColor;
        const content = banner.content;
        const link = banner.link;
        return (
          <li
            key={index}
            className={`banner__slide absolute inset-0 transition-opacity duration-500 ease-in-out
            ${index === activeBanner ? "opacity-100" : "opacity-0"}`}
            tabIndex={index === activeBanner ? -1 : undefined}
          >
            {!image && (
              <div
                className="h-450px"
                style={{
                  backgroundColor: backgroundColor,
                }}
              />
            )}
            {image && (
              <Img
                alt={image.altText}
                fluid={image.imageFile.childImageSharp.fluid}
                objectFit="cover"
                objectPosition="50% 50%"
                className="h-450px"
              />
            )}
            <div className="z-20 absolute inset-0 flex flex-col justify-center items-center">
              {bannerTitle && (
                <h2 className="text-5xl font-bold text-gray pb-0">
                  {bannerTitle}
                </h2>
              )}
              {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
              {link && (
                <Link to={link.url} className="btn btn-white mt-4">
                  {link.title}
                </Link>
              )}
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default BannerSlider;
