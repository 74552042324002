import { graphql } from "gatsby";
import { FixedObject } from "gatsby-image";
import React from "react";

export const fragment = graphql`
  fragment BioFragment on WordPress_Page_Pagesections_Sections_Bio {
    __typename
    fieldGroupName
    title
    description
    image {
      altText
      sourceUrl
      imageFile {
        childImageSharp {
          fixed(width: 125, height: 125) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`;

export interface BioFragment {
  __typename: string;
  fieldGroupName: string;
  title: string;
  description: string;
  image: {
    altText: string;
    sourceUrl: string;
    imageFile: {
      childImageSharp: FixedObject;
    };
  };
}

interface BioProps {
  title: string;
  description: string;
  image: {
    altText: string;
    sourceUrl: string;
    imageFile: {
      childImageSharp: FixedObject;
    };
  };
}

const Bio: React.FC<BioProps> = ({ title, description }) => {
  return (
    <div className="container mx-auto py-8 px-3">
      <h2>{title}</h2>
      <div dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
};

export default Bio;
