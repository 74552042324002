import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import Sections, { PageSection } from "../components/sections";
import SEO from "../components/seo";

export const pageQuery = graphql`
  {
    wordPress {
      page(id: "home", idType: URI) {
        pageSections {
          sections {
            ...BannerSliderFragment
            ... on WordPress_Page_Pagesections_Sections_Editor {
              __typename
              editor
              fieldGroupName
            }
          }
        }
        title
      }
    }
  }
`;

interface IndexProps {
  data: {
    wordPress: {
      page: {
        pageSections: {
          sections: [PageSection];
        };
        title: string;
      };
    };
  };
}

const IndexPage: React.FC<IndexProps> = ({ data }) => {
  const title = data.wordPress.page.title;
  const sections = data.wordPress.page.pageSections.sections;
  return (
    <Layout>
      <SEO title={title} />
      <Sections sections={sections} />
    </Layout>
  );
};

export default IndexPage;
