import { graphql } from "gatsby";
import React from "react";

export const fragment = graphql`
  fragment EditorFragment on WordPress_Page_Pagesections_Sections_Editor {
    __typename
    editor
    fieldGroupName
  }
`;

export interface EditorFragment {
  __typename: string;
  editor: string;
  fieldGroupName: string;
}

interface EditorProps {
  editor: string;
}

const Editor: React.FC<EditorProps> = ({ editor }) => {
  return (
    <div className="container mx-auto py-8 px-3">
      <div dangerouslySetInnerHTML={{ __html: editor }} />
    </div>
  );
};

export default Editor;
